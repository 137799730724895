#pagination {
  display: flex;
  justify-content: center;
  /* position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0); */
}

#pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  /* padding-inline-start: 40px; */
}

#pagination li {
  display: list-item;
  text-align: -webkit-match-parent;
}

#pagination a {
  padding: 0.375rem 0.75rem;
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#pagination a {
  margin-left: -1px;
}

/* #pagination li.previous a,
#pagination li.next a,
#pagination li.break a {
  border-color: transparent;
} */

#pagination li.selected a {
  background-color: #0366d6;
  border-color: transparent;
  color: white;
  min-width: 32px;
}
#pagination li.disabled a {
  color: grey;
}
#pagination li.disable,
#pagination li.disabled a {
  cursor: default;
}

.wrapper {
  /* height: 100%; */
}
