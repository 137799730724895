/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.fixed-plugin .button-container .github-btn {
  display: inline-block;
  font-size: 14px;
}

/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/
.fixed-plugin .SocialMediaShareButton {
  display: inline-block;
  margin: 0 2px;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.all-icons input {
  border: 0;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav>li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.fixed-plugin {
  position: absolute;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 10px;
  padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  max-height: 175px;
  margin: 0 auto;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-white {
  background-color: #ffffff;
}

.fixed-plugin .badge-black {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-azure {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-green {
  background-color: #87cb16;
}

.fixed-plugin .badge-orange {
  background-color: #ffa534;
}

.fixed-plugin .badge-purple {
  background-color: #9368e9;
}

.fixed-plugin .badge-red {
  background-color: #fb404b;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 5px 2px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  text-align: center;
}

.fixed-plugin .pro-title {
  margin: 10px 0 5px 0;
  text-align: center;
}

.fixed-plugin #sharrreTitle {
  text-align: center;
  padding: 10px 0;
  height: 50px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}

.fixed-plugin .adjustments-line .switch {
  float: right;
}

.fixed-plugin .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  max-height: 86px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.switch-trigger:hover,
.fixed-plugin .dropdown-menu>li>a.switch-trigger:focus {
  background-color: transparent;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown-menu>li>a img {
  margin-top: auto;
}

.fixed-plugin .btn-social {
  width: 50%;
  display: block;
  width: 48%;
  float: left;
  font-weight: 600;
}

.fixed-plugin .btn-social i {
  margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
  margin-right: 2%;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  opacity: 1;
}

@media (min-width: 992px) {
  .fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 27px;
    opacity: 0;

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }

  .fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
  }

  .fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -15px;
  }

  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  .fixed-plugin {
    top: 300px;
  }
}

@media (max-width: 991px) {
  .fixed-plugin .dropdown-menu {
    right: 60px;
    width: 220px;
  }

  .fixed-plugin .dropdown-menu li {
    width: 50%;
  }

  .fixed-plugin li.adjustments-line,
  .fixed-plugin li.header-title,
  .fixed-plugin li.button-container {
    width: 100%;
    height: 55px;
    min-height: inherit;
  }

  .fixed-plugin .adjustments-line .switch {
    float: right;
    margin: 0 0px;
  }

  .fixed-plugin li.header-title {
    height: 40px;
  }

  .fixed-plugin .dropdown .dropdown-menu {
    top: -170px;
  }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}

.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}

.btn-social.btn-round {
  padding: 9px 10px;
}

.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}

.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open>.btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}

.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open>.btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}

.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open>.btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}

.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open>.btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.all-icons input {
  border: 0;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav>li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;

  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}

.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}

.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

.gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #fcfcfc),
      color-stop(100%, #eee));
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}

.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0, #eee),
      color-stop(100%, #ddd));
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}

.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.gh-count {
  position: relative;
  display: none;
  /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}

.gh-count:hover,
.gh-count:focus {
  color: #4183c4;
}

.gh-count:before,
.gh-count:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}

.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}

.github-btn-large {
  height: 30px;
}

.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}

.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}

.github-btn-large .gh-count {
  margin-left: 6px;
}

.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}

.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}

.typography-line span {
  bottom: 10px;
  color: #9a9a9a;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  left: 5px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.typography-line {
  padding-left: 15%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

/* 
 * UPDATE CSS
 */

.hr-0 {
  margin: 0px 10px;
}

.listing-item td {
  font-size: 14px;
  font-weight: bold;
  font-family: "Graphik Webfont", -apple-system, "Helvetica Neue", "Droid Sans", Arial, sans-serif;
  margin: 0;
  padding: 5px 0;
}

.listing-item .value {
  font-size: 16px;
  color: white;
  text-align: right;
}

.listing-item .listing-tag {
  border-top: 1px;
}

.listing-item .btn-add-to-collection {
  width: 25px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 30px;
  color: white;
  cursor: pointer;
}

.listing-item .btn-add-to-collection:hover {
  color: mediumseagreen;
}

.listing-item .btn-favorite {
  width: 25px;
  height: 40px;
  position: absolute;
  right: 10px;
  color: white;
  cursor: pointer;
}

.listing-item .btn-favorite:hover {
  color: #dc3545;
}

.listing-item .btn-favorite-active {
  width: 25px;
  height: 40px;
  position: absolute;
  right: 10px;
  color: #dc3545;
  cursor: pointer;
}

.icon-favorite {
  font-size: 40px;
  text-align: center;
  color: #dc3545;
  margin-top: -12px;
}

.listing-item .custom-icon {
  font-size: 20px;
}

#search-filter-icon {
  position: fixed;
  color: green;
  right: 30px;
  top: calc(50% - 30px);
  z-index: 9999;
  font-size: 40px;
}

.modal-search-filter {
  /* width: 1920px; */
}

#collection-page .modal-edit-collection .label-edit-collection {
  margin: 0px;
}

.collection-item .btn-edit-collection {
  width: 25px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  display: none;
}

.collection-item:hover .btn-edit-collection {
  display: block;
  color: mediumseagreen;
}

#popup-add-to-collection {
  position: absolute;
  height: 230px;
  width: 180px;
  background: white;
  display: none;
  z-index: 1000;
}

#popup-add-to-collection #collections-list {
  overflow-y: auto;
  border-top: 1px solid;
  border-color: lightgray;
  height: 142px;
}

#popup-add-to-collection .card label {
  color: black;
}

#popup-add-to-collection .card-footer {
  padding: 10px;
}

#popup-add-to-collection #input-add-new-collection {
  padding: 5px;
  height: 10px;
  border-radius: 0px;
  width: 125px;
}

#popup-add-to-collection .form-control::placeholder {
  /* font-style: italic; */
  color: gray;
}

#popup-add-to-collection #btn-add-new-collection {
  float: right;
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin: 0px;
  padding: 4px;
  border-radius: 0px;
  height: 31px;
}

#shop-item td {
  font-size: 14px;
  font-weight: bold;
  font-family: "Graphik Webfont", -apple-system, "Helvetica Neue", "Droid Sans", Arial, sans-serif;
  margin: 0;
  padding: 5px 0;
}

#shop-item .value {
  font-size: 16px;
  color: white;
  text-align: right;
}

#shop-item .shop-tag {
  border-top: 1px;
}

#pagination #container {
  margin: 1rem;
}

#pagination .items {
  margin-bottom: 1rem;
}

.loading {
  height: calc(100vh - 180px);
}

.rotate-infinite-slow {
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

#bot-page .message-log {
  overflow: scroll;
  min-height: 100px;
  max-height: 100px;
  background: black;
  color: white;
  margin-top: 10px;
}

#bot-page .message-log-2 {
  overflow: scroll;
  /* min-height: 100px; */
  height: calc(100vh - 500px);
  background: white;
}

.modal-task-logs {
  top: -100px;
  height: calc(100% + 200px);
}

.modal-task-logs-list {
  overflow: scroll;
  height: 400px;
  background-color: black;
  padding: 10px;
  color: white;
}

.modal-listing-sold {
  top: -150px;
  height: calc(100% + 200px);
}

.modal-listing-sold td {
  padding: 6px !important;
}

#bot-page ::-webkit-scrollbar {
  width: 10px;
}

#bot-page ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .6);
}

#bot-page .btn-clear-log {
  position: relative;
  float: right;
  right: 5px;
  top: -65px;
}

#bot-page .assign-task-bot {
  background-color: #00bbff;
  color: white;
}

#bot-page .check-whole-shop label {
  color: white;
}

#bot-page .btn-reload-bot {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.filter-group {
  margin-bottom: 10px;
}

/* fix */
.form-check input {
  opacity: 1 !important;
  position: absolute;
  visibility: visible !important;
}

.form-check .form-check-label {
  padding-left: 5px;
}

.btn-group-listing {
  float: right;
}

.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

.text-green {
  color: #28a745;
}

.text-blue-chart {
  color: rgb(44, 175, 254)
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.listing-sold-days-radio input {
  margin-top: 6px !important;
}

.draggable-zone {
  opacity: 0.001;
  z-index: 1000;
  position: absolute;
  background-color: yellow;
  width: 100%;
  height: 100%;
}

.draggable {
  opacity: 0.001 !important;
}

.ui-draggable-dragging {
  /* background: #8b0000 !important; */
  opacity: 0.001 !important;
}

#draggable:active {
  opacity: 0.001 !important;
}

.content {
  height: 100%;
}

#view-3d,
#view-3d-area {
  height: 100%;
}

#stl-container {
  height: calc(100% - 30px);
  margin: -15px;
  margin-top: 30px;
}

.stl-viewer-component #floating-button-group {
  width: 250px;
  height: 400px;
  position: absolute;
  top: 100px;
  right: 10px;
  text-align: center;
  font-size: 11pt;
  opacity: 0.8;
  z-index: 100;
}

.stl-viewer-component #floating-button-group button {
  width: 80px;
  opacity: 1 !important;
}

.stl-viewer-component #floating-button-group table tr {
  border: 1px solid lightgrey;
  color: rgba(63, 82, 63, 0.773);
}

.stl-viewer-component #floating-button-group table {
  margin: 10px auto;
}

.stl-viewer-component #floating-button-group .update-model {
  font-size: 11pt;
}

.stl-viewer-component #toggle-floating-group {
  font-size: 32px;
  position: absolute;
  top: 100px;
  right: 45px;
  width: 20px;
  height: 20px;
  z-index: 200;
  cursor: pointer;
  color: #0c98b5;
}

.modal-config-lamp {
  /* z-index: 1000; */
  top: -150px;
  height: calc(100% + 200px);
}

.stl-viewer-component .btn-icon {
  font-size: 28px;
  cursor: pointer;
  width: 40px;
  text-align: center;
  color: #0c98b5 !important;
}

.color-red {
  color: red;
}

#box-maker-page .svg-preview {
  background: #ccc;
}

#shop-sold .view-mode-option {
  /* position: relative;
  text-align: center; */
  font-size: 20px;
}

/* @media (min-width: 992px) {
  #shop-sold .view-mode-option {
    position: absolute;
    float: left;
    top: 2em;
    left: 45px;
    font-size: 18px;
  }
} */

#shop-sold .date-time {
  position: relative;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: -40px;
}

@media (min-width: 992px) {
  #shop-sold .date-time {
    position: relative;
    float: right;
    margin-top: 0px;
    /* top: 1em;
    right: 45px; */
    font-size: 20px;
  }
}

#shop-sold .team-title {
  margin-top: 50px ! important;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

@media (min-width: 992px) {
  #shop-sold .team-title {
    margin-top: 20px ! important;
  }
}

#shop-sold .shop-title {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.shop-sold-chart .view-shop-listing-sold {
  cursor: pointer;
  position: relative;
  top: 30px;
  right: 10px;
  font-size: 20px;
  text-align: right;
  z-index: 1000;
}

.modal-shop-listing-sold .modal-dialog {
  height: 100%;
  top: -20%;
}

.modal-shop-listing-sold .modal-content {
  height: 80%;
  overflow-y: auto;
}


.modal-shop-listing-sold {
  border-top: 1px solid #ccc;
}

.modal-shop-listing-sold img {
  padding: 10px 0;
}

.truncated-text {
  white-space: nowrap;
  /* Không xuống dòng */
  overflow: hidden;
  /* Ẩn phần bị tràn */
  text-overflow: ellipsis;
  /* Thêm dấu "..." */
  max-width: 400px;
  /* Giới hạn độ rộng */
  display: block;
  /* Hiển thị dưới dạng block */
}

.modal-shop-listing-sold .text-header {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.modal-shop-listing-sold .text-modal-title {
  font-size: 26px;
}

.text-bold {
  font-weight: bold;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

#autop2p-qr-page {
  /* width: 80%; */
  margin: 0 auto;
}

#autop2p-qr-page .input-group-text {
  width: 150px;
}

#autop2p-qr-page table {
  border: none; /* Bỏ border của toàn bộ table */
}

#autop2p-qr-page table td, table th {
  border: none; /* Bỏ border của các ô và tiêu đề */
}

#autop2p-qr-page .payment-item {
  margin: 40px 0;
  /* padding: 40px; */
  border: 1px solid #ccc;
}

#autop2p-qr-page .payment-item td {
  font-weight: bold;
}

#autop2p-qr-page .qr-containerX {
  display: flex;
  justify-content: right;
  /* align-items: center; */
  height: 100%;           /* Đảm bảo chiều cao đầy đủ */
  padding: 20px;          /* Thêm padding để cân đối khoảng cách (nếu cần) */
}

#autop2p-qr-page .qrX {
  display: flex;
  align-items: center;    /* Đảm bảo Col chứa mã QR căn giữa */
  justify-content: center;
  height: 100%;           /* Đảm bảo chiều cao của cột chứa */
  min-height: 300px;      /* Đặt chiều cao tối thiểu để đảm bảo hiển thị đẹp */
}

#autop2p-qr-page .qr-container {
  position: relative; /* Đảm bảo canvas nằm trong container */
  width: 100%;
  max-width: 500px !important;
  margin-top: 8px;
}

#autop2p-qr-page .qr-containerX canvas {
  width: 100%; /* Canvas chiếm toàn bộ chiều rộng container */
  height: auto; /* Chiều cao tự động theo tỷ lệ */
  display: block; /* Tránh tạo khoảng trắng dưới canvas */
}

#autop2p-qr-page .extra-infos {
  border: 1px solid #bbb;
  background-color: gainsboro;
}

#autop2p-qr-page .extra-infos div {
  margin: 10px 0;
}

#autop2p-qr-page .payment_option {
  /* border-bottom: 1px solid #ccc; */
}

#autop2p-qr-page .bank-dropdown {
  width: calc(100% - 150px);
}

#autop2p-qr-page button {
  width: 100px;
}

#autop2p-qr-page .order-link {
  color: blue;
    /* position: absolute;
        bottom: 10px; */
}

#autop2p-qr-page .raw-info {
  background-color: darkgray;
  padding: 10px;
  border: 1px solid gray;
}

#autop2p-qr-page .report-timestamp {
  /* background-color: gainsboro; */
}

/* Sidebar */
.sidebar {
  width: 30px; /* Kích thước thu nhỏ */
  position: fixed;
  height: 100%;
  transition: width 0.3s ease;
  z-index: 100;
  background-color: #333;
}

.sidebar:hover {
  width: 250px; /* Kích thước mở rộng */
}

/* Main panel */
.main-panel {
  margin-left: 30px; /* Khoảng cách khi sidebar thu nhỏ */
  transition: margin-left 0.3s ease;
  padding: 20px;
  width: calc(100% - 30px);
}

.sidebar:hover + .main-panel {
  margin-left: 250px; /* Điều chỉnh khi sidebar mở rộng */
  width: calc(100% - 250px);
}

.order-link a {
  color: blue;
  word-break: break-word; /* Ngắt dòng khi vượt quá không gian */
  word-wrap: break-word; /* Tương thích với trình duyệt cũ */
}

.word-break {
  word-break: break-word; /* Ngắt dòng khi vượt quá không gian */
  word-wrap: break-word; /* Tương thích với trình duyệt cũ */
}

